import httpClient from "./httpClient";
import { AxiosResponse } from "axios";
import EmdAddress from "@/model/emdAddress";

export default class EmdAddressService {
  static myTc(): Promise<AxiosResponse<EmdAddress>> {
    return httpClient.get(`/my-tc`);
  }

  static dnaLaboratory(): Promise<AxiosResponse<EmdAddress[]>> {
    return httpClient.get(`/dna-laboratory`);
  }
}
