<template>
  <ViewTemplate title="Dashboard">
    <Alerts :title="tc.instid" :priority="4" />
  </ViewTemplate>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Alerts from "@/components/Alerts.vue";
import EmdAddress from "@/model/emdAddress";
import EmdAddressService from "@/service/emdAddress";

@Options({
  components: {
    Alerts
  }
})
export default class Dashboard extends Vue {
  tc = {} as EmdAddress;
  async mounted() {
    await EmdAddressService.myTc().then(resp => (this.tc = resp.data));
  }
}
</script>

<style scoped></style>
